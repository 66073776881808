@import './base/bootstrap.scss';
@import './base/bootstrap-extended.scss';
@import './base/colors.scss';
@import './base/components.scss';

// Themes
@import './base/themes/dark-layout.scss';
@import './base/themes/bordered-layout.scss';
@import './base/themes/semi-dark-layout.scss';
// @import './base/custom-rtl.scss';

// React Specific
@import 'react/index';

.loader-component {
    background-color: rgb(248, 248, 248, 60%);
    position: fixed;
    z-index: 1000000;
}

.dark-layout {
    .loader-component {
        background-color: rgb(22, 29, 49, 60%);
    }
}

.my-masonry-grid {
    display: -webkit-box; /* Not needed if autoprefixing */
    display: -ms-flexbox; /* Not needed if autoprefixing */
    display: flex;
    margin-left: -30px; /* gutter size offset */
    width: auto;
    &.custom-gap {
        margin-left: -20px;
    }
}
.my-masonry-grid_column {
    padding-left: 30px; /* gutter size */
    background-clip: padding-box;
    &.custom-gap_column {
        padding-left: 20px;
    }
}
.dragging {
    opacity: 0.5;
}

.icon-color {
    color: #333333;
}

.footer-custom-margin {
    margin-right: 7px;
}
.validation-error {
    margin-top: 0.25rem;
    font-size: 1rem;
    color: #ea5455;
}

.list-group-flush {
    .draggable-list {
        border-radius: 6px;
        &:not(:last-child) {
            margin-bottom: 14px;
        }
    }
}

.header-label {
    // font-size: 21px !important;
    font-weight: 700 !important;
    color: #1d1d1d;
}
.user-profile-img {
    width: 200px;
    height: 150px;
    border-radius: 0.357rem;
    background-color: #f3e8e4;
    color: var(--primary);
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    font-weight: bold;
    font-size: 50px;
    border: 1px light grey;
}
.user-profile-name {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #f3e8e4;
    color: var(--primary);
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    font-weight: bold;
    font-size: 15px;
    border: 1px solid lightgrey;
}

.icon-color {
    color: #333333;
}
.project-sub-text {
    font-size: 13px;
}
.custom-zIndex {
    z-index: 0 !important;
}

.team-col-gap {
    gap: 12px;
}

.team-board {
    border-right: 1px solid #ccc;
}

@media (max-width: 767.98px) {
    .team-board {
        border-right: none;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    .team-board-sm-xs {
        border-right: 1px solid #ccc;
        margin-bottom: 10px;
    }
}

.team-box-border {
    border: 1px solid #ccc;
    border-radius: 6px;
}
.divider-font {
    color: grey;
    font-size: 16px !important;
    font-weight: bold;
}

.btn-custom {
    height: 30px;
    width: 30px;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.css-1nmdiq5-menu {
    z-index: 3 !important;
}
.project-profile-img {
    width: 100px;
    height: 100px;
    border-radius: 0.357rem;
    background-color: #f3e8e4;
    color: #9a4521;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    font-weight: bold;
    font-size: 25px;
}
.annex-table {
    border: 1px solid #dee2e6;
    padding: 4px;
    text-align: center;
}
.nav.custom-scrollbar {
    display: flex;
    flex-wrap: nowrap;
}
.project-table-head {
    background-color: #f3f3f3;
    color: black;
}
.project-table {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid #dee2e6;
}
.project-table-body {
    font-weight: 500;
    color: black;
}
.Project-label {
    display: block;
    margin-bottom: 0px;
    font-weight: 900;
    font-size: 14px;
}
.nav-pills .nav-link.custom-pill-padding {
    padding: 0.586rem 1.5rem !important;
}
.project-profile-accordion {
    background-color: #d9d9d9b5;
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
}

.heading-text-shimmer {
    .react-loading-skeleton {
        width: 150px;
        @media only screen and (min-width: 576px) {
            width: 250px;
        }
        @media only screen and (min-width: 768px) {
            width: 350px;
        }
        @media only screen and (min-width: 1024px) {
            width: 500px;
        }
    }
}
// ** Data Grid Shimmer
.data-grid-header-skeleton {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 10px 0 5px 0;
    @media only screen and (min-width: 631px) {
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
    }
    > div {
        display: flex;
        justify-content: flex-end;
        align-items: flex-start;
        width: auto;
        margin-left: 0 !important;
        margin-top: 5px;
        margin-right: 10px;
        margin-bottom: 3px;
        @media only screen and (min-width: 631px) {
            margin-top: 0;
        }
    }
}

.data-grid-footer-skeleton {
    display: block;
    margin-top: 10px;
    @media only screen and (min-width: 631px) {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

.custom-modal {
    @media only screen and (min-width: 576px) {
        max-width: 500px;
    }
    @media only screen and (min-width: 768px) {
        max-width: 600px;
    }
    @media only screen and (min-width: 992px) {
        max-width: 900px;
    }
    @media only screen and (min-width: 1024px) {
        max-width: 1000px;
    }
}
.custom-material-modal {
    @media only screen and (min-width: 576px) {
        max-width: 500px;
    }
    @media only screen and (min-width: 700px) {
        max-width: 650px;
    }
    @media only screen and (min-width: 750px) {
        max-width: 730px;
    }
    @media only screen and (min-width: 850px) {
        max-width: 830px;
    }
    @media only screen and (min-width: 900px) {
        max-width: 860px;
    }
    @media only screen and (min-width: 992px) {
        max-width: 950px;
    }
    @media only screen and (min-width: 1150px) {
        max-width: 1100px;
    }
    @media only screen and (min-width: 1250px) {
        max-width: 1200px;
    }
}

.modal-lg-2 {
    --bs-modal-width: 55%;
}

.text-right-align {
    text-align: right;
}
.text-left-align {
    text-align: left;
}

.report-font-weight .dx-datagrid .dx-row > td {
    padding: 0px 11px;
}

.common-grid-column-no-image {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: #dcdcdc;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    font-weight: bold;
    font-size: 12px;
}

.common-grid-column-image {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    margin-right: 10px;
    object-fit: cover;
}

.no-padding-grid .dx-datagrid-rowsview .dx-master-detail-row > .dx-master-detail-cell {
    padding: 0px !important;
}

.custom-data-grid .dx-datagrid-headers,
.custom-data-grid .dx-datagrid-content,
.custom-data-grid .dx-datagrid-rowsview,
.custom-data-grid .dx-datagrid-borders > .dx-datagrid-headers,
.custom-data-grid .dx-datagrid-borders > .dx-datagrid-rowsview,
.custom-data-grid .dx-datagrid-borders > .dx-datagrid-total-footer {
    border-top: 0 !important;
    border-left: 0 !important;
    border-right: 0 !important;
}

.custom-data-grid .dx-datagrid-rowsview .dx-row {
    border-top: 0 !important;
    border-left: 0 !important;
    border-right: 0 !important;
}

.custom-data-grid .dx-datagrid-borders > .dx-datagrid-total-footer {
    border-left: 0 !important;
    border-right: 0 !important;
}

.grid-fix-height {
    height: 75vh;
}
