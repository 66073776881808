// Basic accordion
.accordion {
    [data-bs-toggle='collapse'] {
        font-weight: 500;
        font-size: 1.1rem;
        line-height: $line-height-base;
    }
    .accordion-item {
        margin-bottom: 0;
        &:last-of-type {
            margin-bottom: 0;
        }
        &:not(:last-of-type) {
            border-bottom: 1px solid $border-color;
        }
    }
    .accordion-body {
        padding-top: 0.42rem;
    }
}

// accordion without icon
.accordion {
    &.accordion-without-arrow {
        .accordion-button::after {
            background-image: none !important;
        }
    }
}

.accordion-item {
    &.accordion-without-arrow {
        .accordion-button::after {
            background-image: none !important;
        }
    }
}

// For Accordion with border
.accordion-border {
    .accordion-item {
        border: 1px solid $border-color;
        border-radius: $card-border-radius;
        &:not(:last-of-type) {
            border-bottom: 0;
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
        }
        &:not(:first-of-type) {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
        }
    }
}

// For Accordion with margin
.accordion-margin {
    .accordion-item {
        margin-top: 0.71rem;
        margin-bottom: 0.71rem;
        box-shadow: 0 2px 15px 0 rgba(34, 41, 47, 0.1) !important;
        border-radius: $border-radius;
        border-bottom: 0 solid transparent !important;
    }
}

.card.accordion-item {
    .accordion-button {
        border-radius: $border-radius;
    }
}
